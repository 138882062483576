import axios from "axios";
import React, { useEffect, useState } from "react";

const Homepage = () => {
  const [message, setMessage] = useState([]);
  const [another, setAnother] = useState([]);


  useEffect(() => {
    // fetch('/api/message')
    //   .then((response) => response.json())
    //   .then((data) => setMessage(data.message))
    //   .catch((error) => console.error('Error fetching message:', error));
    getData();
    getAnotherRouteData();
  }, []);

  // const getData = async () => {
  //   try {
  //     const response = await axios.get("/api/message");
  //     // console.log('Response:', response);
  //     setMessage(response.data.message);
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //   }
  // }

  const getData = async () => {
    try {
      const response = await axios.get("https://blog-test-backend.logicspice.in/homepageget");
      setMessage(response.data.response);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getAnotherRouteData = async () => {
    try {
      const response = await axios.get("https://blog-test-backend.logicspice.in/api/message");
      setAnother(response.data.message);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  console.log(message);

  return (
    <div>
      <h1>Data from backend</h1>
      {message.map((i) => {
        return (
          <>
            <div className="">
              <p>Title is: {i.title}</p>
              <p>Description is: {i.description}</p>
            </div>
            
          </>
        );
      })}
      <h1>Data from another route</h1>
      <p>{another}</p>
      {/* {another.map((i)=> {
        return(
          <p>i.message</p>
        )
      })} */}
    </div>
  );
};

export default Homepage;
